import { IGetWeeklyPractice } from 'api/v1/prana-game';
import { useState } from 'react';
import { PracticeCardContainer } from '../css';
import numberUtils from 'shared/utils/number';
// import { useAppSelector } from 'shared/hooks/redux-helper';
import PracticeModal from './practice-modal';
import { useGetWeeklyPracticeQuery } from 'store/query';
import Loader from 'shared/components/loader';

// interface IPracticeCardProps {
// 	data: IGetWeeklyPractice['practices'];
// 	setRefresh: (refresh: number) => void;
// 	refresh: number;
// }

const PracticeCard = () => {
	const [modalStatus, setModalStatus] = useState<{ status: boolean }>({ status: false });
	const [selectedPractice, setSelectedPractice] = useState<IGetWeeklyPractice['practices'][0]['days'][0]>();
	const { data, isLoading } = useGetWeeklyPracticeQuery();

	return !isLoading && data ? (
		<PracticeCardContainer>
			{[...data.practices]
				.filter(practice => practice.status === 'doing')
				.sort((a, b) => a.weekNo - b.weekNo)
				.map((practices, i) => (
					<div className='grouped' key={`group_${i}`}>
						<div className='collection' key={`${i}-${practices.weekNo}`}>
							<h5>
								{practices.weekNo}
								{practices.weekNo === 1 ? 'st' : practices.weekNo === 2 ? 'nd' : practices.weekNo === 3 ? 'rd' : 'th'} Week
								- {practices.weekTitle}
							</h5>
							{[...practices.days]
								.sort((a, b) => {
									const order: { [key: string]: number } = { pending: 1, losted: 2, done: 3 };
									return order[a.claimedStatus] - order[b.claimedStatus];
								})
								.map((day, index) => (
									<div
										key={`${index}-${day.title}-${day.id}`}
										className='border'
										onClick={() => {
											if (!day.isCurrent || day.claimedStatus !== 'pending') {
												return;
											}
											setSelectedPractice(day);
											if (day.claimedStatus === 'pending') {
												setModalStatus({ status: true });
											}
										}}
									>
										{(day.claimedStatus === 'losted' || day.claimedStatus === 'pending') && !day.isCurrent && (
											<div className='lock_caption'>
												<img src='/img/shop/lock.webp' />
											</div>
										)}
										{day.claimedStatus === 'done' && (
											<div className='bought'>
												<img src='/img/shop/bought.webp' className='tick' />
											</div>
										)}
										<div className='container_timer'>
											<img src={day.icon} alt='' className='clock' />
										</div>
										<div className='content'>
											<p>{day.title}</p>
											<div>
												<p>
													Profit: <span>{numberUtils.formatPriceWithDecimals(day.profit)}</span>
												</p>
												<p className='cost'>
													{/* 
													Cost: <img src='/img/dollar.webp' alt='' />
													{numberUtils.formatPriceWithDecimals(peractice.cost)} */}
												</p>
											</div>
										</div>
									</div>
								))}
						</div>
					</div>
				))}
			{[...data.practices]
				.filter(practice => practice.status === 'todo')
				.sort((a, b) => a.weekNo - b.weekNo)
				.map((practices, i) => (
					<div className='grouped_colaps' key={`collapsed_${i}`}>
						<div className='collection' key={`${i}-${practices.weekNo}`}>
							<h5>
								{practices.weekNo}
								{practices.weekNo === 1 ? 'st' : practices.weekNo === 2 ? 'nd' : practices.weekNo === 3 ? 'rd' : 'th'} Week
								- {practices.weekTitle}
							</h5>
							<div>
								<img src='/img/shop/lock.webp' />
							</div>
						</div>
					</div>
				))}
			{[...data.practices]
				.filter(practice => practice.status === 'done')
				.sort((a, b) => a.weekNo - b.weekNo)
				.map((practices, i) => (
					<div className='grouped_colaps' key={`collapsed_p_${i}`}>
						<div className='collection' key={`${i}-${practices.weekNo}`}>
							<h5>
								{practices.weekNo}
								{practices.weekNo === 1 ? 'st' : practices.weekNo === 2 ? 'nd' : practices.weekNo === 3 ? 'rd' : 'th'} Week
								- {practices.weekTitle}
							</h5>
							<div className=''>
								<img src='/img/shop/bought.webp' className='tick' />
							</div>
						</div>
					</div>
				))}
			{modalStatus.status && selectedPractice && (
				<PracticeModal
					setRefresh={() => {}}
					// setRefresh={() => setRefresh(refresh + 1)}
					data={selectedPractice}
					setStatus={status => setModalStatus({ status })}
					status={modalStatus.status}
				/>
			)}
		</PracticeCardContainer>
	) : (
		<Loader />
	);
};
export default PracticeCard;
