import { getLevelsThunk } from 'store/reducers/levels';
import { useAppDispatch, useAppSelector } from './redux-helper';
import { useEffect } from 'react';

export const useLevels = () => {
	const data = useAppSelector(store => store.levels.levels);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (data && data.length > 0) return;

		dispatch(getLevelsThunk());
	}, [data]);

	return data;
};
