import requestHandler from 'shared/utils/request-handler.ts';
import { ICanClaimRewardResponse, IClaimRewardResponse } from './secret-code.dto.ts';

const BASE_URL = '/api/v1/DailySecretCode';

export const canClaimReward = async () =>
	await requestHandler.call<ICanClaimRewardResponse>({ url: `${BASE_URL}/CanClaimReward`, method: 'get' }).then(res => res.data);

export const POSTClaimReward = async (code: string) =>
	await requestHandler.call<IClaimRewardResponse>({ url: `${BASE_URL}/ClaimReward?code=${code}`, method: 'post' }).then(res => res.data);
