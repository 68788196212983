import { GETFriends, IFriendResponse } from 'api/v1/friend';
import { createCustomApi } from 'store/base.api';

const apis = {
	'friend-list': GETFriends,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const PranaSliceApi = createCustomApi({
	reducerPath: 'friend-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getFriendList: builder.query<IFriendResponse, void>({
			query: () => ({ path: 'friend-list' }),
		}),
	}),
});

export const { useGetFriendListQuery } = PranaSliceApi;

export default PranaSliceApi;
