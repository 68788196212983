import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GameCardModalContainer = styled.div(
	({ theme }) => css`
		position: relative;
		color: ${theme.colors.text.primary};
		padding: 40px 24px 10px;
		height: 270px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		justify-content: space-around;
		.header_img {
			width: 250px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -115px;
		}
		.header_img_ {
			width: 144px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -115px;
		}
		h3 {
			font-size: 24px;
			font-weight: 700;
			line-height: 29.71px;
			text-align: center;
			span {
				font-size: 20px;
				font-weight: 600;
				line-height: 24.76px;
				text-align: center;
			}
		}
		h1 {
			font-size: 32px;
			font-weight: 800;
			line-height: 39.62px;
			text-align: center;

			.dollar {
				width: 25px;
				height: 25px;
				margin-bottom: 2px;
			}
		}
		p {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			text-align: center;
		}
		button {
			width: 100%;
		}
		.btn {
			background: linear-gradient(
				93deg,
				rgba(124, 3, 9, 0.6334908963585435) 16%,
				rgba(78, 4, 11, 0.700717787114846) 50%,
				rgba(0, 0, 0, 0.8071603641456583) 100%
			) !important;
		}
	`,
);
