import { GETReferralRewards, IReferralRewardResponse } from 'api/v1/referral-reward';
import { createCustomApi } from 'store/base.api';

const apis = {
	'referral-reward': GETReferralRewards,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const ReferralRewardSliceApi = createCustomApi({
	reducerPath: 'referral-reward-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getReferralReward: builder.query<IReferralRewardResponse[], void>({
			query: () => ({ path: 'referral-reward' }),
		}),
	}),
});

export const { useGetReferralRewardQuery } = ReferralRewardSliceApi;

export default ReferralRewardSliceApi;
