import { GETCampaign, IGetCampaignResponse, ITasks } from 'api/v1/campaign';
import { GETLotteryAPI, ILotteryResponse } from 'api/v2/lottery';
import { createCustomApi } from 'store/base.api';
import { AppDispatch } from 'store/types';

const apis = {
	lottery: GETLotteryAPI,
	campaign: GETCampaign,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const GameSliceApi = createCustomApi({
	reducerPath: 'games-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getLottery: builder.query<ILotteryResponse, void>({
			query: () => ({ path: 'lottery' }),
		}),
		getCampaign: builder.query<IGetCampaignResponse & { waitIfFail?: number; loadingTaskId?: number | null }, void>({
			query: () => ({ path: 'campaign' }),
		}),
	}),
});

export const updateBuyLottery = (data: { hasBuy: boolean; codes: string[] }) => (dispatch: AppDispatch) => {
	dispatch(
		GameSliceApi.util.updateQueryData('getLottery', undefined, draft => {
			draft.ticket.hasBuyed = data.hasBuy;
			draft.ticket.codes.push(...data.codes);
		}),
	);
};

export const updateCampaign =
	(data: { isClaimedReward: boolean; tasks: ITasks[]; waitIfFail: number; loadingTaskId?: null | number }) => (dispatch: AppDispatch) => {
		dispatch(
			GameSliceApi.util.updateQueryData('getCampaign', undefined, draft => {
				draft.loadingTaskId = data.loadingTaskId || null;
				draft.waitIfFail = data.waitIfFail || 0;
				draft.isClaimedReward = data.isClaimedReward;
				draft.tasks = data.tasks;
			}),
		);
	};
export const { useGetLotteryQuery, useGetCampaignQuery } = GameSliceApi;

export default GameSliceApi;
