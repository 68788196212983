import { AcademyContainer } from './css';

const Academy = () => {
	return (
		<AcademyContainer>
			<img src='/img/academy/academy.webp' alt='' />
			<p>
				This work is inspired by fantasy themes and is not affiliated with or endorsed by HBO, Game of Thrones, or related
				properties."
			</p>
		</AcademyContainer>
	);
};

export default Academy;
