import { useState } from 'react';
import { ArrowRightOutline } from 'shared/components/icons';
import Loader from 'shared/components/loader';
import numberUtils from 'shared/utils/number';
import { BoostersContainer } from '../css';
import { EnergyVector, MultiTapVector, Up, Full } from '../img';
import BoostModal from './boost-modal';
import Icon from 'shared/components/icon';
import { useCountDown } from 'shared/hooks/use-count-down';
import useChangingProgress from '../hooks/use-changing-progress';
import { CircleProgress } from 'shared/components/progress-bar';
import { useGetBoostersQuery, useGetFullEnergyQuery } from 'store/query';

const Boosters = () => {
	const [boostModal, setBoostModal] = useState<{
		status: boolean;
		price?: number;
		reward?: number;
		id?: number;
		title: string;
		type: 'EnergyLimit' | 'Multitap' | 'FullEnergy';
		currentLevel?: number;
	}>({
		id: 0,
		price: 0,
		reward: 0,
		status: false,
		title: '',
		type: 'EnergyLimit',
		currentLevel: 0,
	});
	const { data } = useGetBoostersQuery();
	const { data: fullEnergy } = useGetFullEnergyQuery();
	const total = fullEnergy?.nextTodayClaimTime ? (fullEnergy.nextTodayClaimTime * 1000 - Date.now()) / 60000 : 0;
	const values = Array.from({ length: total + 1 }, (_, i) => i);
	const currentValue = useChangingProgress({ total, values });
	// const replay = useAppSelector(state => state.profile.replay);
	return (
		<BoostersContainer>
			<div className='header'>
				<div className='booster_title'>Boosters</div>
			</div>
			{data && (
				<div className='cards_container'>
					{fullEnergy && (
						<div className='border'>
							<div
								className='booster_card'
								onClick={() => {
									setBoostModal({
										title: 'Full Energy',
										type: 'FullEnergy',
										status: true,
									});
								}}
							>
								<div className='img_container_timer'>
									<CircleProgress
										width={65}
										percentage={currentValue}
										strokeWidth={4}
										primaryColor={['#0EEAE0', '#5A71F0']}
										secondaryColor='#003457'
										children={<Full className='full_icon' />}
									/>
								</div>
								<div className='title'>Full Energy</div>
								<div className='value'>
									<div className='coin'>
										{fullEnergy.remainedCount}/ {fullEnergy.remainedCount + fullEnergy.claimedCount} Available
									</div>

									<div className='clock_up'>
										<Icon name='clock' className='clock' />
										{fullEnergy.nextTodayClaimTime > 0 && (
											<Timer timeLeft={fullEnergy.nextTodayClaimTime * 1000 - Date.now()} />
										)}
									</div>
								</div>
								<ArrowRightOutline className='arrow' />
							</div>
						</div>
					)}
					{data.map((item, index) => (
						<div className='border' key={`boost_item_${index}`}>
							<div
								className='booster_card'
								onClick={() => {
									setBoostModal({
										id: item.id,
										price: item.cost,
										reward: item.reward,
										title: item.title,
										type: item.type,
										status: true,
										currentLevel: item.currentLevel,
									});
									// }
								}}
							>
								<div className='img_container'>
									{item.type === 'Multitap' && <MultiTapVector />}
									{item.type === 'EnergyLimit' && <EnergyVector />}
								</div>
								<div className='title'>{item.title}</div>
								<div className='value'>
									<div className='coin'>
										<img width={14} height={14} src='/img/dollar.webp' alt='' draggable={false} />
										{numberUtils.formatPriceWithDecimals(item.cost)}
									</div>

									<div className='up'>
										<Up /> {item.currentLevel + 1} lvl
									</div>
								</div>
								<ArrowRightOutline className='arrow' />
							</div>
						</div>
					))}
				</div>
			)}
			{!data && <Loader />}

			{boostModal.status && (
				<BoostModal
					status={boostModal.status}
					setStatus={status => {
						setBoostModal({ ...boostModal, status });
					}}
					id={boostModal?.id}
					price={boostModal.price}
					reward={boostModal.reward}
					title={boostModal.title}
					currentLevel={boostModal.currentLevel}
					type={boostModal.type}
					nextTime={fullEnergy?.nextTodayClaimTime ? fullEnergy?.nextTodayClaimTime : undefined}
				/>
			)}
		</BoostersContainer>
	);
};
export const Timer = ({ timeLeft }: { timeLeft: number }) => {
	const { hours, minutes, seconds } = useCountDown(timeLeft);

	const formatTime = () => {
		return +hours > 0
			? `${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`
			: `${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
	};

	return <span>{formatTime()}</span>;
};

export default Boosters;
