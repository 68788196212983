import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const PineyeContainer = styled.section(
	({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		flex-direction: column;
		padding-top: 50px;

		.title {
			font-size: 24px;
			font-weight: 600;
			line-height: 30px;
			text-align: center;
		}
		.character {
			width: 50%;
			display: none;
			position: relative;
			z-index: 2;

			&.active {
				display: block;
			}
		}

		.character_light {
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: 10px;
			width: 245px;
			height: 200px;
			filter: blur(40px);
			backface-visibility: hidden;
			transform: translate3d(0, 0, 0);

			&.style_1 {
				background: radial-gradient(54% 54% at 50% 50%, #682702 0%, #7fb1d1 100%);
			}
			&.style_2 {
				background: radial-gradient(50% 50% at 50% 50%, #ec0000 0%, #7fb1d1 100%);
			}
			&.style_3 {
				background: radial-gradient(50% 50% at 50% 50%, #ec7100 0%, #7fb1d1 100%);
			}
			&.style_4 {
				background: radial-gradient(50% 50% at 50% 50%, #fcd535 0%, #7fb1d1 100%);
			}
			&.style_5 {
				background: radial-gradient(50% 50% at 50% 50%, #69fc35 0%, #7fb1d1 100%);
			}
			&.style_6 {
				background: radial-gradient(50% 50% at 50% 50%, #35fcf0 0%, #7fb1d1 100%);
			}
			&.style_7 {
				background: radial-gradient(50% 50% at 50% 50%, #4535fc 0%, #7fb1d1 100%);
			}
			&.style_8 {
				background: radial-gradient(50% 50% at 50% 50%, #d435fc 0%, #7fb1d1 100%);
			}
			&.style_9 {
				background: radial-gradient(50% 50% at 50% 50%, #f200a0 0%, #7fb1d1 100%);
			}
			&.style_10 {
				background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #7fb1d1 100%);
			}
		}
		.arow_container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			color: ${theme.colors.text.primary};
			margin-top: 12px;
			gap: 8px;
			position: absolute;
			z-index: 2;
			padding: 0 16px;
			.circle {
				border-radius: 50%;
				background-color: #ffffff20;
				padding: 15px 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				svg {
					width: 24px;
					height: 24px;

					&.arrow_left {
						transform: rotate(180deg);
					}
					&.disable {
						opacity: 0.5;
					}
				}
			}
		}
	`,
);
