import { GETGetAllTokenBalance, IGetAllTokenBalanceResponse } from 'api/v1/token-balance';
import { GETAllWallets, IWalletResponse } from 'api/v1/wallet';
import { createCustomApi } from 'store/base.api';

const apis = {
	wallets: GETAllWallets,
	tokenBalance: GETGetAllTokenBalance,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const WalletSliceApi = createCustomApi({
	reducerPath: 'wallets-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		wallets: builder.query<IWalletResponse[], void>({
			query: () => ({ path: 'wallets' }),
		}),
		tokenBalance: builder.query<IGetAllTokenBalanceResponse[], void>({
			query: () => ({ path: 'tokenBalance' }),
		}),
	}),
});

export const { useWalletsQuery, useTokenBalanceQuery } = WalletSliceApi;

export default WalletSliceApi;
