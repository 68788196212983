import { useEffect, useState } from 'react';
import { ShopPranaContainer } from './css';
import { Tabs, Cards, PracticeCard } from './components';
import numberUtils from 'shared/utils/number';
import { useAppSelector } from 'shared/hooks/redux-helper';
import Loader from 'shared/components/loader';
import { IGetPranaMarketplace } from 'api/v1/prana-game';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useGetMarketPlaceQuery } from 'store/query';

const PranaShop = () => {
	const [selectedTab, setSelectedTabs] = useState<'Character' | 'Finance' | 'Practice'>('Character');
	const [selectedTabData, setSelectedTabData] = useState<IGetPranaMarketplace['categories']>();
	const profit = useAppSelector(state => state.profile.profit);
	const balance = useAppSelector(state => state.profile.balance);
	const navigate = useNavigate();

	const { data: marketCache, isLoading } = useGetMarketPlaceQuery();

	useEffect(() => {
		window.Telegram.WebApp.BackButton.onClick(() => {
			navigate('/');
		});
		return () => {
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, []);

	useEffect(() => {
		if (marketCache && selectedTab !== 'Practice') {
			const selectedData = marketCache?.categories.find(category => category.title === selectedTab);
			setSelectedTabData(selectedData ? [selectedData] : undefined);
		}
	}, [selectedTab, marketCache]);
	return (
		<ShopPranaContainer>
			<Helmet>
				<title>PinEye | Prana Shop</title>
			</Helmet>
			<div className='bottom_light' />
			<img src='/img/game/banner-prana.webp' alt='' className='banner' />
			<div className='header'>
				<div className='coins_container'>
					<div className='coin'>
						<img src='/img/dollar.webp' alt='' />
						<p>{numberUtils.formatPriceWithDecimals(balance)}</p>
					</div>
					<div className='coin'>
						<img src='/img/profit.webp' alt='' />
						<p>{numberUtils.formatPriceWithDecimals(profit)}</p>
					</div>
				</div>
				<div className='how'>
					<a href='https://docs.pineye.io/prana' id='prana_how_it_work' target='_blank' rel='noreferrer'>
						How it works?
					</a>
				</div>
			</div>

			<Tabs setSelectedTabs={setSelectedTabs} />
			{isLoading && <Loader />}
			{selectedTabData && selectedTab !== 'Practice' && <Cards key={'cards-shop'} data={selectedTabData} selectedTab={selectedTab} />}
			{selectedTab === 'Practice' && <PracticeCard />}
		</ShopPranaContainer>
	);
};
export default PranaShop;
