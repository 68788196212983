import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { POSTClaimReward } from 'api/v1/secret-code';
import { Helmet } from 'react-helmet';
import { SecretCodeContainer } from './secret-code.style';
import Button from 'shared/components/button';
import { changeBalance } from 'store/reducers/profile';
import { MyPicker } from './picker';
import InfoModal from './info-modal';
import Loader from 'shared/components/loader';
import { updateCanClaimStatus, useGetCanClaimQuery } from 'store/query/daily-secret-code';
import { useAppDispatch } from 'shared/hooks/redux-helper';

const SecretCode = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [statusModal, setStatusModal] = useState(false);

	const [pickerValue, setPickerValue] = useState({
		val1: '5',
		val2: '5',
		val3: '5',
		val4: '5',
	});
	const dispatch = useAppDispatch();
	const { data, isLoading: canClaimLoading } = useGetCanClaimQuery();
	const answered = Boolean(data && !data.canClaim);

	const isLoading = canClaimLoading || loading;

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		function ensureDocumentIsScrollable() {
			const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
			if (!isScrollable) {
				document.documentElement.style.setProperty('height', 'calc(100vh + 1px)', 'important');
			}
		}

		function preventCollapse() {
			if (window.scrollY === 0) {
				window.scrollTo(0, 1);
			}
		}

		window.addEventListener('load', ensureDocumentIsScrollable);

		const scrollableElement = document.querySelector('.picker');
		scrollableElement?.addEventListener('touchstart', preventCollapse);

		return () => {
			document.body.style.overflow = 'auto';
			window.removeEventListener('load', ensureDocumentIsScrollable);
			scrollableElement?.removeEventListener('touchstart', preventCollapse);
		};
	}, []);

	const onSubmit = () => {
		if (!isLoading) {
			setLoading(true);
			const code = `${pickerValue.val1}${pickerValue.val2}${pickerValue.val3}${pickerValue.val4}`;
			POSTClaimReward(code)
				.then(res => {
					window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
					dispatch(changeBalance({ balance: res.balance }));
					toast.success(res.message);
					dispatch(updateCanClaimStatus(false));
				})
				.catch(res => {
					toast.error(res.data.cause);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const handleTouch = () => {
		window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
	};

	useEffect(() => {
		const touchElement = document.querySelector('.picker');
		touchElement?.addEventListener('touchstart', handleTouch);
		touchElement?.addEventListener('touchend', handleTouch);

		return () => {
			touchElement?.removeEventListener('touchstart', handleTouch);
			touchElement?.removeEventListener('touchend', handleTouch);
		};
	}, []);
	console.log(answered);
	return (
		<SecretCodeContainer>
			<Helmet>
				<title>PinEye | Secret Code</title>
			</Helmet>
			<img src='/img/secret-code/bg.webp' alt='' className='bg' />
			<div className='head'>
				<div
					className='lamp'
					onClick={() => {
						setStatusModal(true);
					}}
				>
					<img src='/img/secret-code/lamp.webp' alt='' />
				</div>
			</div>
			{isLoading && <Loader />}
			{!answered && !isLoading ? (
				<div className='lock_container'>
					<img src='/img/secret-code/cl.webp' alt='' className='lock' />
					<div className='picker_container'>
						<div className='first' />
						<div className='second' />
						<div className='third' />
						<div className='fourth' />
						<div className='line_1' />
						<div className='line_2' />
						<div className='line_3' />
						<MyPicker setPickerValue={setPickerValue} pickerValue={pickerValue} />
					</div>
				</div>
			) : (
				!isLoading && (
					<div className='lock_container'>
						<img src='/img/secret-code/ol.webp' alt='' className='lock' />
					</div>
				)
			)}
			{!isLoading && (
				<div className='bottom'>
					{!answered ? (
						<Button id='check_secret_code' disabled={isLoading} variant='primary' onClick={onSubmit}>
							Check
						</Button>
					) : (
						<p>
							Code accepted. Please wait for
							<br /> the next one...
						</p>
					)}
				</div>
			)}
			{statusModal && <InfoModal setStatus={setStatusModal} status={statusModal} />}
		</SecretCodeContainer>
	);
};

export default SecretCode;
