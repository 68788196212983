import { Helmet } from 'react-helmet';
import Loader from 'shared/components/loader';
import { Header, IncomeList } from './components';
import { RewardsHistoryContainer } from './css';
import { useGetRewardHistoryQuery } from 'store/query';

const RewardHistory = () => {
	const { data } = useGetRewardHistoryQuery();

	return (
		<RewardsHistoryContainer>
			<Helmet>
				<title>PinEye | reward history</title>
			</Helmet>
			<div className='top_light'></div>
			<Header />
			{data ? <IncomeList data={data} /> : <Loader />}
		</RewardsHistoryContainer>
	);
};

export default RewardHistory;
