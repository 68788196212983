import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FriendsListContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		margin-bottom: 8px;
		display: grid;
		grid-template-columns: 1fr;
		column-span: all;
		gap: 10px;
		background: #3993cb;
		border: 1.5px solid transparent;
		border-radius: 21px;
		background-image: linear-gradient(#3993cb, #3993cb),
			linear-gradient(200.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
		background-origin: border-box;
		background-clip: padding-box, border-box;
		padding: 15px 14px 18px;
		position: relative;

		h2 {
			font-size: 18px;
			font-weight: 600;
			line-height: 22.28px;
			padding-left: 8px;
		}

		.empty_container {
			display: flex;
			flex-direction: column;
			gap: 12px;
			align-items: center;
			justify-content: center;
			padding: 24px 0 20px;

			p {
				font-size: 14px;
				font-weight: 400px;
				line-height: 17px;
			}

			svg {
				width: 24px;
				height: 24px;
			}
		}

		.list_container {
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding: 12px 0 20px;

			.list_item_inner {
				background: #005385;
				padding: 10px 16px;
				border-radius: 8px;
				display: grid;
				grid-template-columns: 40px 1fr auto;
				grid-template-rows: 1fr 1fr;
				gap: 4px 12px;
				align-items: center;
				justify-content: space-between;

				.avatar {
					width: 40px;
					height: 40px;
					grid-column: 1;
					grid-row: 1/3;
				}

				.username {
					color: #fff;
					font-size: 16px;
					font-weight: 600;
					line-height: 19.81px;
					grid-column: 2;
					grid-row: 1;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				.level_title {
					color: #fff;
					font-size: 12px;
					font-weight: 400;
					line-height: 14.56px;
					margin-top: 4px;
					grid-column: 2;
					grid-row: 2;
				}

				.value {
					display: flex;
					align-items: center;
					gap: 6px;
					color: #fff;
					justify-content: space-around;
					background: #074368;
					padding: 7px;
					border-radius: 6px;
					grid-row: 1/3;
					p {
						font-size: 14px;
						font-weight: 600;
						line-height: 17.33px;
						color: #28c7f3;
					}
					img {
						width: 16px;
						height: 16px;
						margin-bottom: 2px;
					}
				}

				&:last-of-type {
					border: none;
				}
			}
		}

		.invite_container {
			display: grid;
			grid-template-columns: 1fr 48px;
			gap: 8px;
			position: fixed;
			z-index: 2;
			bottom: 120px;
			width: calc(100% - 48px);
			left: 24px;
			.invite_btn {
				background: linear-gradient(272.78deg, #902bf5 14.14%, #03dcce 91.66%);
				border-radius: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 12px 0;
				font-weight: 600;
				font-size: 18px;
				line-height: 22px;
				gap: 4px;
				color: ${theme.colors.text.primary};
				border: 1.4px solid #9cefebb2;
				box-shadow: 0px 4px 4px 0px #ffffffb8 inset;

				svg {
					margin-bottom: 1px;
				}
			}

			.copy_btn {
				background: #902cf6;
				border-radius: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: ${theme.colors.text.primary};
				border: 1.4px solid #9cefebb2;
				box-shadow: 0px 4px 4px 0px #ffffffb8 inset;
			}
		}
	`,
);
