import requestHandler from 'shared/utils/request-handler';
import { IBoosterResponse, IBuyBoosterResponse } from './booster.dto';

const BASE_URL = '/api/v2/Booster';

export const GETBoosters = async () => {
	return requestHandler.call<IBoosterResponse[]>({ url: `${BASE_URL}`, method: 'get' }).then(res => res.data);
};

export const POSTBuyBooster = async (id: number) => {
	return requestHandler.call<IBuyBoosterResponse>({ url: `${BASE_URL}/Buy?boosterId=${id}`, method: 'post' }).then(res => res.data);
};
