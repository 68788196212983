import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GamesContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		position: relative;
		display: grid;
		padding: 24px;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 25px;
		grid-row-gap: 20px;
		z-index: 3;
		.div1 {
			grid-area: 1 / 1 / 2 / 2;
		}
		.div2 {
			grid-area: 1 / 3 / 2 / 4;
		}
		.div3 {
			grid-area: 2 / 1 / 3 / 2;
		}
		.div4 {
			grid-area: 2 / 3 / 3 / 4;
		}
		.div5 {
			grid-area: 1 / 2 / 3 / 3;
		}
		justify-items: center;
		justify-content: center;
		margin-bottom: 8px;
		.border {
			padding: 1.5px;
			background: linear-gradient(180.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
			border-radius: 14px;
			position: relative;
			width: 69px;

			.main {
				background: linear-gradient(180deg, #3cb1fa 30%, #0080d0 77%);
				border-radius: 12.5px;
				position: relative;
				text-align: center;
				img {
					height: 45px;
					top: -10px;
					position: relative;
					left: 0;
					right: 0;
					margin: auto;
					/* position: absolute;
                   
                    bottom: 67px; */
				}
				p {
					font-size: 10px;
					font-weight: 500;
					line-height: 12.38px;
					text-align: center;
					top: -10px;
					position: relative;
				}
			}
			.black {
				background: linear-gradient(181.25deg, #7a7979 17.82%, #2a2929 87.45%);
			}
		}

		@media (max-width: 360px) {
			grid-column-gap: 20px;
			grid-row-gap: 20px;
			.border {
				width: 50px;
				.main {
					img {
						height: 30px;
					}
				}
			}
		}
	`,
);
