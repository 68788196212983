import { GETReferralBalance, IReferralBalance } from 'api/v1/referral-balance';
import { createCustomApi } from 'store/base.api';

const apis = {
	'reward-history': GETReferralBalance,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const RewardHistorySliceApi = createCustomApi({
	reducerPath: 'reward-history-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getRewardHistory: builder.query<IReferralBalance[], void>({
			query: () => ({ path: 'reward-history' }),
		}),
	}),
});

export const { useGetRewardHistoryQuery } = RewardHistorySliceApi;

export default RewardHistorySliceApi;
