import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const OverviewContainer = styled.section(
	({ theme }) => css`
		display: grid;
		grid-template-columns: 1fr 2px 1.2fr 1px 1fr;
		grid-template-rows: 1fr;
		padding: 9px 16px;
		color: ${theme.colors.text.primary};
		position: relative;
		justify-content: center;
		align-items: baseline;

		.level {
			display: flex;
			justify-content: center;
			gap: 7px;
			align-items: center;
			color: #fff;
			flex-direction: column;
			align-self: flex-start;
			.level_p {
				font-size: 10px;
			}
			.progress_container {
				width: 95%;
				height: 12px;
				border-radius: 13px;
				background: linear-gradient(227.14deg, #7dcdff -1.29%, #1fa1f0 29.94%, #0074da 87.8%);
				margin: auto;
				display: flex;
				align-items: center;
				justify-content: center;
				/* padding: 8px 11px; */
				box-shadow: 0px 1px 4px 0px #ffffffb8 inset;
				box-shadow: 0px 2px 11px 0px #0000004a;
				.border {
					width: 96%;
					height: 12px;
					border-radius: 13px;
					background: #0958a8;
					box-shadow: 0px 2px 4px 0px #ffffffc2 inset, 0px 0px 6px 0px #000000;
				}

				.filled {
					height: 100%;
					border-radius: 12px;
					width: 0;
					transition: all 300ms ease;
					position: relative;
					background: linear-gradient(90deg, #6800d0 7.01%, #902bf5 31.49%, #5e6cff 63.44%, #54beff 97.94%);
					box-shadow: 0px 2px 4px 0px #ffffff inset, 0px 4px 4px 0px #002fec24;
				}
			}
			img {
				width: 29px;
			}
			.inner {
				display: flex;
				align-items: center;
				flex-direction: column;
				font-size: 11px;
				font-weight: 400;
				line-height: 13.34px;
			}
			.card_item {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 6px;
				color: ${theme.colors.text.primary};
				.pic {
					width: 29px;
					height: 29px;
					&.right {
						width: 32px;
						height: 32px;
						margin-bottom: -4px;
					}
				}
				.text_container {
					display: flex;
					padding-top: 3px;
					justify-content: center;
					/* flex-direction: column;
					gap: 2px;
					align-items: left;
					justify-content: center; */
					/* .title {
						font-size: 15px;
						font-weight: 400;
						line-height: 18.57px;
					} */
					.value {
						font-size: 13px;
						font-weight: 700;
						line-height: 16.09px;
						span {
							font-weight: 400;
						}
					}
				}
			}
		}

		.dividerr {
			width: 1px;
			height: 32px;
			background: #ffffff30;
		}
		.divider-h {
			width: 28%;
			height: 1px;
			background: #ffffff30;
			position: absolute;
			top: 60px;
		}

		@media (max-height: 620px) {
			.level {
				img {
					/* width: 20px; */
				}
				.inner {
					font-size: 9px;
					line-height: 10.9px;
					img {
						width: 10px;
					}
				}
			}
		}
	`,
);
