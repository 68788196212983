import { FC } from 'react';
import Modal from 'shared/components/modal';
import { InfoModalContainer } from '../css';
import useCloseModal from 'shared/hooks/use-close-modal';
import Button from 'shared/components/button';
import { useNavigate } from 'react-router-dom';

interface IInfoMoalProps {
	status: boolean;
	setStatus: (status: boolean) => void;
}

const InfoModal: FC<IInfoMoalProps> = ({ status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const navigate = useNavigate();
	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			<InfoModalContainer>
				<img src={'/img/prana/done.webp'} alt='' className='calender' />
				<div className='content'>
					<h3>Congratulation 🎉</h3>
					<p>
						You’ve successfully completed your practice today, and your prana has improved!
						<br />
						We invite you to return tomorrow. Keep up the great work!
					</p>

					<Button
						className='btn'
						variant='primary'
						onClick={() => {
							navigate('/prana/shop');
							onClose();
						}}
					>
						Continue
					</Button>
				</div>
			</InfoModalContainer>
		</Modal>
	);
};

export default InfoModal;
