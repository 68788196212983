import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AcademyContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		position: relative;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;

			right: 0;
			margin: auto;
		}
		p {
			position: relative;
			z-index: 1;
			text-align: center;
			padding: 32px 16px;
			font-size: 12px;
			font-weight: 400;
			line-height: 13.78px;
		}
	`,
);
