import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GETLevels, GETPlayers, IGetPlayersResponse, ILevelResponse } from 'api/v2/levels';
import { ILevels, IReduxState } from 'store/types';

const initialState: { levels: ILevels[] } = { levels: [] };

export const Levels = createSlice({
	name: 'levels',
	initialState: initialState,
	reducers: {
		setLevels: (state, { payload }: PayloadAction<ILevelResponse[]>) => {
			return {
				...state,
				levels: payload.map(item => ({ ...item, players: { data: [], page: -1, hasMore: true } })),
			};
		},
		updatePlayers: (
			state,
			{ payload }: PayloadAction<{ levelId: number; players: IGetPlayersResponse[]; page: number; hasMore: boolean }>,
		) => {
			const index = state.levels.findIndex(item => item.level === payload.levelId);
			if (index > -1) {
				state.levels[index].players.data.push(...payload.players);
				state.levels[index].players.page = payload.page;
				state.levels[index].players.hasMore = payload.hasMore;
			}
			return state;
		},
	},
});

export const getLevelsThunk = createAsyncThunk('get-levels', async (_, { dispatch }) => {
	const levels = await GETLevels();
	dispatch(setLevels(levels));
});

export const getPlayersByLevelThunk = createAsyncThunk(
	'get-players-by-level',
	async ({ level }: { level: number }, { dispatch, getState }) => {
		const {
			levels: { levels },
		} = getState() as IReduxState;
		const levelIndex = levels.findIndex(item => item.level === level);

		if (levelIndex > -1) {
			const currentLevel = levels[levelIndex];
			if (currentLevel.players.hasMore) {
				const page = currentLevel.players.page;
				const newPage = page >= 1 ? page + 1 : 1;
				const data = await GETPlayers(newPage, level);

				dispatch(updatePlayers({ levelId: level, players: data, page: newPage, hasMore: Boolean(data && data.length > 0) }));
			}
		}
	},
);

export const { setLevels, updatePlayers } = Levels.actions;
export default Levels.reducer;
