import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { changeNotifs } from 'store/reducers/profile';
import { Games, LevelProgress, Overview, Pineye, ProfitModal } from './components';
import LotteryModal from './components/lottery-modal';
import NotifModal from './components/notif-modal';
import { HomeContainer } from './css';

const Home = () => {
	const [notifModal, setNotifModal] = useState<boolean>(true);
	const [lotteryModalStatus, setLotteryModal] = useState<boolean>(true);
	const [profitModalStatus, setProfitModalStatus] = useState(true);

	const dispatch = useDispatch();

	const notifs = useAppSelector(state => state.profile.notif);
	const profile = useAppSelector(state => state.profile);

	useEffect(() => {
		window.Telegram.WebApp.BackButton.isVisible = false;

		return () => {
			window.Telegram.WebApp.BackButton.isVisible = true;
		};
	}, []);

	useEffect(() => {
		window.scroll({ top: 2 });
		const touchCancel = (e: TouchEvent) => {
			e.preventDefault();
		};
		document.addEventListener('touchstart', touchCancel);
		return () => {
			document.removeEventListener('touchstart', touchCancel);
		};
	}, []);

	const onCloseNotifs = (state: boolean) => {
		setNotifModal(state);
		const newNotifs = notifs.filter(item => item.type === 'LotteryReward' || item.type === 'pranaProfit');
		dispatch(changeNotifs(newNotifs));
	};
	const onCloseLottery = (state: boolean) => {
		setLotteryModal(state);
		const newNotifs = notifs.filter(item => item.type !== 'LotteryReward');
		dispatch(changeNotifs(newNotifs));
	};
	const onCloseProfitModal = (state: boolean) => {
		setProfitModalStatus(state);
		const newNotifs = notifs.filter(item => item.type !== 'pranaProfit');
		dispatch(changeNotifs(newNotifs));
	};

	return (
		<HomeContainer>
			<Helmet>
				<title>PinEye | home</title>
			</Helmet>

			<img src={`/img/new-home/${profile.level.no ?? 1}-1.webp`} alt='' className='base_img' />
			<img src={`/img/new-home/${profile.level.no ?? 1}.webp`} alt='' className='rotate_img' />
			<Overview />
			<Games />
			<Pineye />
			<LevelProgress />
			{notifModal && notifs.filter(item => item.type !== 'LotteryReward' && item.type !== 'pranaProfit').length > 0 && (
				<NotifModal status={notifModal} setStatus={onCloseNotifs} />
			)}
			{lotteryModalStatus && notifs.find(item => item.type === 'LotteryReward') && (
				<LotteryModal status={true} setStatus={onCloseLottery} />
			)}

			{profitModalStatus && notifs.find(item => item.type === 'pranaProfit') && (
				<ProfitModal
					setStatus={onCloseProfitModal}
					status={profitModalStatus}
					key={'profit-modal'}
					profit={+notifs.find(item => item.type === 'pranaProfit')!.metatData}
				/>
			)}
		</HomeContainer>
	);
};
export default Home;
