import requestHandler from 'shared/utils/request-handler';
import { IBalanceResponse, IBoosterResponse, IGetPendingTaskResponse, ILevelResponse, INotifsResponse } from './profile.dto';

const BASE_URL = '/api/v3/Profile';

export const GETBalanceAPI = async () => {
	return requestHandler.call<IBalanceResponse>({ url: `${BASE_URL}/GetBalance`, method: 'get' }).then(res => res.data);
};

export const GETLevelAPI = async () => {
	return requestHandler.call<ILevelResponse>({ url: `${BASE_URL}/UpdateAndGetLevel`, method: 'put' }).then(res => res.data);
};

export const GETPendingTaskAPI = async () => {
	return requestHandler.call<IGetPendingTaskResponse>({ url: `${BASE_URL}/GetPendingTask`, method: 'get' }).then(res => res.data);
};

export const GETNotifsAPI = async () => {
	return requestHandler.call<INotifsResponse>({ url: `${BASE_URL}/GetNotifs`, method: 'get' }).then(res => res.data);
};

export const POSTBuyBoosterAPI = async (id: number) => {
	return requestHandler.call<IBoosterResponse>({ url: `${BASE_URL}/BuyBooster?boosterId=${id}`, method: 'post' }).then(res => res.data);
};
