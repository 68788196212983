import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { LevelProgressContainer } from '../css';
import numberUtils from 'shared/utils/number';
import InfoModal from 'pages/games/components/info-modal';

const LevelProgress = () => {
	const profit = useAppSelector(state => state.profile.profit);
	const level = useAppSelector(state => state.profile.level);

	const [statusModal, setStatusModal] = useState(false);
	const [type, setType] = useState('');
	const navigate = useNavigate();

	return (
		<LevelProgressContainer>
			<div className='info'>
				<div
					onClick={() => {
						setStatusModal(true);
						setType('Profit');
					}}
				>
					<div
						className='level'
						onClick={() => {
							setStatusModal(true);
							setType('Profit');
						}}
					>
						<img src='/img/profit.webp' width={25} height={25} alt='' />
						<p>{numberUtils.formatPriceWithDecimals(profit)}</p>
					</div>
				</div>

				<div onClick={() => navigate('/leagues')}>
					<Link className='level' to={level.title ? '/leagues' : ''}>
						<img src='/img/pineye/cup.webp' width={25} height={25} alt='' /> {level.title ?? ''}
					</Link>
				</div>

				<div onClick={() => navigate('/boost')}>
					<Link to={'/boost'} className='boost'>
						<img src='/img/pineye/jet.webp' width={25} height={25} alt='' />
						Boost
					</Link>
				</div>
			</div>
			{statusModal && type.length > 0 && (
				<InfoModal setStatus={setStatusModal} setType={() => setType('')} type={type} status={statusModal} />
			)}
		</LevelProgressContainer>
	);
};
export default LevelProgress;
