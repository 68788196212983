import { PyramidContainer } from './css';

const Pyramid = () => {
	return (
		<PyramidContainer>
			<img src='/img/game/main-game.webp' alt='' />
		</PyramidContainer>
	);
};

export default Pyramid;
