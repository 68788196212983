import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const LevelProgressContainer = styled.section(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 12px;
		color: ${theme.colors.text.primary};
		background: #3caff475;

		position: fixed;
		bottom: -22px;
		width: 97%;
		padding: 0 24px 120px;
		border: 2px solid #ffffff4a;
		border-radius: 40px 40px 0 0px;
		user-select: none;
		left: 0;
		right: 0;
		margin: 0 auto;
		height: 200px;
		justify-content: center;
		z-index: 2;
		/* .flower_container {
			display: flex;
			align-items: center;
			gap: 10px;
			
		} */

		.info {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: 1fr;
			gap: 8px;
			align-items: center;
			justify-content: space-between;
			div {
				padding: 1px;
				background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
				border-radius: 6px;
				.level,
				.energy,
				.boost {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					font-weight: 400;
					line-height: 17px;
					gap: 8px;
					background: #0d7dc2;
					border-radius: 5px;
					height: 41px;
					padding: 10px 5px;
					color: #fff;
					img {
						width: 25px;
						height: 25px;
					}
				}
				.level {
					gap: 3px;
					padding-right: 10px;
					align-items: end;
					text-wrap: nowrap;
					img {
						width: 21px;
						height: 21px;
					}
				}
				.energy {
					gap: 5px;
					p {
						width: 65px;
						font-size: 13px;
						font-weight: 400;
						line-height: 17px;
					}
				}
			}
		}
	`,
);
