import { GETDailyRewards, IDailyRewardResponse } from 'api/v1/daily-reward';
import { createCustomApi } from 'store/base.api';

const apis = {
	'daily-rewards': GETDailyRewards,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const DailyRewardSliceApi = createCustomApi({
	reducerPath: 'daily-reward-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getDailyRewards: builder.query<IDailyRewardResponse, void>({
			query: () => ({ path: 'daily-rewards' }),
			onQueryStarted: (_, { getCacheEntry }) => {
				console.log(getCacheEntry());
			},
		}),
	}),
});

export const { useGetDailyRewardsQuery } = DailyRewardSliceApi;

export default DailyRewardSliceApi;
