import requestHandler from 'shared/utils/request-handler';
import { IGetCampaignResponse, ICheckCamaignResponse, IClaimRewardResponse } from './campaign.dto';

const BASE_URL = '/api/v1/Campaign';

export const GETCampaign = async () => {
	return requestHandler.call<IGetCampaignResponse>({ url: `${BASE_URL}`, method: 'get' }).then(res => res.data);
};

export const POSTCheckCampaign = async (campaignId: number, taskId: number) => {
	return requestHandler
		.call<ICheckCamaignResponse>({ url: `${BASE_URL}/Check?campaignId=${campaignId}&taskId=${taskId}`, method: 'post' })
		.then(res => res.data);
};

export const POSTClaimReward = async (campaignId: number) => {
	return requestHandler
		.call<IClaimRewardResponse>({ url: `${BASE_URL}/Claim?campaignId=${campaignId}`, method: 'post' })
		.then(res => res.data);
};
