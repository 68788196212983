import requestHandler from 'shared/utils/request-handler';
import { IGetFullEnergyPlanResponse, IPostFullEnergyPlanResponse } from './full-energy.dto';

const BASE_URL = '/api/v2/FullEnergy';

export const GETFullEnergy = async () => {
	return requestHandler.call<IGetFullEnergyPlanResponse>({ url: `${BASE_URL}`, method: 'get' }).then(res => res.data);
};

export const POSTSetFullEnergy = async () => {
	return requestHandler.call<IPostFullEnergyPlanResponse>({ url: `${BASE_URL}/SetFullEnergy`, method: 'post' }).then(res => res.data);
};
