import { GETBoosters, IBoosterResponse } from 'api/v2/booster';
import { GETFullEnergy, IGetFullEnergyPlanResponse } from 'api/v2/full-energy';
import { createCustomApi } from 'store/base.api';
import { AppDispatch } from 'store/types';

const apis = {
	booster: GETBoosters,
	'full-energy': GETFullEnergy,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const BoosterSliceApi = createCustomApi({
	reducerPath: 'booster-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getBoosters: builder.query<IBoosterResponse[], void>({
			query: () => ({ path: 'booster' }),
		}),
		getFullEnergy: builder.query<IGetFullEnergyPlanResponse, void>({
			query: () => ({ path: 'full-energy' }),
		}),
	}),
});

export const updateFullEnergy =
	(data: { nextClaimTime: number; remainedCount: number; claimedCount: number }) => (dispatch: AppDispatch) => {
		dispatch(
			BoosterSliceApi.util.updateQueryData('getFullEnergy', undefined, draft => {
				draft.nextTodayClaimTime = data.nextClaimTime;
				draft.remainedCount = data.remainedCount;
				draft.claimedCount = data.claimedCount;
			}),
		);
	};

export const updateBooster = (data: { cost: number; currentLevel: number }) => (dispatch: AppDispatch) => {
	dispatch(
		BoosterSliceApi.util.updateQueryData('getBoosters', undefined, draft => {
			draft.forEach(item => {
				if (item.id === 1) {
					item.currentLevel = data.currentLevel;
					item.cost = data.cost;
				}
				if (item.id === 2) {
					item.currentLevel = data.currentLevel;
					item.cost = data.cost;
				}
			});
		}),
	);
};

export const { useGetBoostersQuery, useGetFullEnergyQuery } = BoosterSliceApi;

export default BoosterSliceApi;
