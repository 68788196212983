import { ThemeProvider } from '@emotion/react';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Menu from 'shared/components/menu';
import { envResolver } from 'shared/configs/env-resolver';
import getDesignToken from 'shared/configs/theme';
import { useAppSelector } from 'shared/hooks/redux-helper';
import UseEnergySync from 'shared/hooks/use-energy-sync';
import UseTapSync from 'shared/hooks/use-tap-sync';
import requestHandler from 'shared/utils/request-handler';
import Telegram from 'shared/utils/telegram';
import {
	changeBalance,
	changeInitialProfile,
	changeNotifs,
	changeProfileLogin,
	changeTasks,
	setInitialEarnPerTap,
	setInitialEnergy,
	setInitialFriendsCounts,
	setInitialLevel,
} from 'store/reducers/profile';
import QrCode from '../qr-code';
import Splash from '../splash';
import Walkthrough from '../walkthrough/walkthrough';
import { LayoutContainer } from './layout.style';
import { GETBalanceAPI, GETLevelAPI, GETNotifsAPI, GETPendingTaskAPI } from 'api/v3/profile';

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
	const isLogin = useAppSelector(state => state.profile.isLogin);
	const walkthrough = useAppSelector(state => state.profile.walkthrough);

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isMobile, setIsMobile] = useState<boolean | null>(null);
	const [localUserId, setUserId] = useState<number>(0);

	const initData = () => {
		GETBalanceAPI().then(res => {
			dispatch(setInitialEnergy(res.energy));
			dispatch(setInitialEarnPerTap(res.profile.earnPerTap));
			dispatch(setInitialFriendsCounts(res.profile.friendsCount));
			dispatch(changeBalance({ balance: res.profile.totalBalance }));
			GETLevelAPI().then(l => {
				dispatch(setInitialLevel(l.level));
			});
			GETNotifsAPI().then(res => {
				dispatch(changeNotifs(res.notifs));
			});
		});

		GETPendingTaskAPI().then(res => {
			dispatch(changeTasks(res));
		});
	};

	useEffect(() => {
		if (isLogin) {
			dispatch(changeProfileLogin({ isLogin: false, avatar: '', name: '' }));
		}
		const { userId } = Telegram().getTelegramData();
		setUserId(+userId);

		if (
			+userId !== 5365969509 && //reza
			+userId !== 546699062 && //SaeedArashfar
			+userId !== 6778585565 && //saeed_developer
			+userId !== 5756020385 && //Ali Pineye
			envResolver.MODE !== 'STAGE' &&
			window.Telegram.WebApp.platform !== 'ios' &&
			window.Telegram.WebApp.platform !== 'android'
		) {
			setIsMobile(false);
		} else {
			setIsMobile(true);
			requestHandler.loginUser().then(res => {
				if (!window.Telegram?.WebApp?.isExpanded) {
					window.Telegram.WebApp.expand();
					document.body.style.height = 'calc(100% + 2px)';
				}
				document.body.style.height = 'calc(100% + 2px)';

				window.Telegram.WebApp.BackButton.isVisible = true;

				window.Telegram.WebApp.BackButton.onClick(() => {
					navigate('/');
				});

				if (res) {
					const { photo_url, username } = res;
					dispatch(changeProfileLogin({ isLogin: true, avatar: photo_url, name: username }));
					dispatch(changeInitialProfile(res.profile));

					UseEnergySync(dispatch);
					UseTapSync(dispatch);
					setIsLoading(false);

					initData();
				}
			});
		}
	}, []);

	return (
		<ThemeProvider theme={getDesignToken()}>
			{(isMobile || localUserId === 5365969509) && (
				<LayoutContainer>
					{isLoading ? (
						<Splash />
					) : !walkthrough ? (
						<Walkthrough />
					) : (
						<>
							<Menu />
							{children}
						</>
					)}
				</LayoutContainer>
			)}

			{isMobile === false && localUserId !== 5365969509 && <QrCode />}

			{/* <div className='bottom_right_light'></div> */}
		</ThemeProvider>
	);
};

export default Layout;
