import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SecretCodeContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		position: relative;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 0.25fr 2.5fr 1.3fr;
		overflow: hidden;
		height: 100vh;
		gap: 10px;
		.bg {
			position: absolute;
			width: 100%;
			height: 100vh;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			/* object-fit: cover; */
		}
		.head {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			z-index: 1;
			padding-right: 24px;
			.lamp {
				position: relative;
				padding: 30px;
				cursor: pointer;
				width: 45px;
				height: 35px;
				img {
					width: 45px;
				}
			}
		}
		.lock_container {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			margin: auto;
			left: 0;
			right: 0;
			width: 100%;
			height: 68vh;
			overflow-y: hidden;
			padding-top: 0px;
			.lock {
				position: absolute;
				left: 0;
				right: 0;
				margin: auto;
				width: 100%;
				margin-top: 0px;
				height: -webkit-fill-available;
			}
			.check {
				position: absolute;
				left: 0;
				right: 0;
				margin: auto;
				width: 115px;
				height: 115px;
			}
			.picker_container {
				position: relative;
				top: 3vh;
				left: 0;
				margin-right: 5vw;
				z-index: 2;
				.line_1,
				.line_2,
				.line_3 {
					position: absolute;
					width: 2px;
					height: 28px;
					background: #000000;
					top: 16vh;
					z-index: 6;
				}
				.line_1 {
					left: 24%;
				}
				.line_2 {
					left: 50%;
				}
				.line_3 {
					left: 75%;
				}
				.first,
				.second,
				.third,
				.fourth {
					position: absolute;
					width: 40px;
					height: 170px;
					border-radius: 30px;
					border: 2px solid #000000;
					box-shadow: -2px 5px 4px 0px #000000d1 inset;
					z-index: 2;
					top: 25px;
					text-align: center;
				}
				.first {
					left: 5px;
				}
				.second {
					left: 52%;
				}
				.third {
					right: 52%;
				}
				.fourth {
					right: 5px;
				}
				.picker {
					z-index: 3;
					.picker_img {
						width: 35px;
						height: 35px;
						padding: 4px;
					}
					& > div:not(.column) {
						border-block: 8px solid #000000;
						background: #94fff4;
						width: 94% !important;
						left: 6px !important;
					}
					.column {
						padding: 7px;
						z-index: 5;
						.items {
							padding-bottom: 10px;
							text-align: center;
						}
					}
				}
			}
		}
		.bottom {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 2;
			top: -60%;
			text-align: center;
			button {
				.wallet {
					width: 24px;
					height: 24px;
					margin-right: 10px;
				}
				width: 89%;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 5;
			}
		}
		@media (min-height: 667px) {
			.lock_container {
				.picker_container {
					.line_1,
					.line_2,
					.line_3 {
						top: 14vh;
					}
				}
			}
		}
		@media (min-height: 700px) {
			.lock_container {
				.picker_container {
					.line_1,
					.line_2,
					.line_3 {
						top: 13vh;
					}
				}
			}
		}
		@media (min-height: 800px) {
			.lock_container {
				.picker_container {
					.line_1,
					.line_2,
					.line_3 {
						top: 12vh;
					}
				}
			}
		}
		@media (min-height: 844px) {
			.lock_container {
				.picker_container {
					.line_1,
					.line_2,
					.line_3 {
						top: 11vh;
					}
				}
			}
		}
		@media (min-height: 915px) {
			.lock_container {
				.picker_container {
					.line_1,
					.line_2,
					.line_3 {
						top: 10vh;
					}
				}
			}
		}
	`,
);
