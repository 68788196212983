import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const PyramidContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		position: relative;
		img {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			right: 0;
			margin: auto;
		}
	`,
);
