import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ProfitContainerModal = styled.div(
	({ theme }) => css`
		position: relative;
		padding: 0px 0 20px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		.header_img {
			width: 133px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -125px;
		}
		.header_img_move {
			animation: move 1.5s ease-in-out 1;
			top: -195px;
			width: 133px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
		}
		@keyframes move {
			0% {
				transform: translate3d(0, 30px, 0);
			}
			100% {
				transform: translate3d(0, 0px, 0);
			}
		}
		.title {
			font-size: 24px;
			font-weight: 500;
			line-height: 27px;
			text-align: center;
			color: ${theme.colors.text.primary};
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 50px;
				margin-right: 8px;
			}
		}
		.caption {
			font-size: 16px;
			font-weight: 600;
			line-height: 23px;
			text-align: center;
			color: ${theme.colors.text.primary};
			padding: 0 16px;
		}

		.reward_container {
			background: #ffffff0d;
			padding: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
			margin-bottom: 32px;

			img {
				width: 18px;
			}

			.title {
				color: ${theme.colors.text.primary};
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;
				margin-top: 1px;
			}
		}

		button {
			margin: 0 24px 24px;
			width: calc(100% - 48px);
		}
	`,
);
