import { GETSocial, ISocialResponse } from 'api/v1/social';
import { createCustomApi } from 'store/base.api';
import { AppDispatch } from 'store/types';

const apis = {
	social: GETSocial,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data };
};

const SocialSliceApi = createCustomApi({
	reducerPath: 'social-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getSocial: builder.query<ISocialResponse[], void>({
			query: () => ({ path: 'social' }),
		}),
	}),
});

export const updateSocialClaimById = (id: number) => (dispatch: AppDispatch) => {
	dispatch(
		SocialSliceApi.util.updateQueryData('getSocial', undefined, draft => {
			const index = draft.findIndex(item => item.id === id);
			if (index > -1) {
				draft[index].isClaimed = true;
				draft[index].claimedScore = draft[index].score;
			}
		}),
	);
};
export const { useGetSocialQuery } = SocialSliceApi;

export default SocialSliceApi;
