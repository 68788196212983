import { FriendsList, Header, IncomeStats } from './components';
import { FriendsContainer } from './css';
import Loader from 'shared/components/loader';
import { Helmet } from 'react-helmet';
import { useGetFriendListQuery, useGetReferralRewardQuery } from 'store/query';

const Friends = () => {
	const { data: friendList, isLoading: friendListLoading } = useGetFriendListQuery();
	const { data: referralReward, isLoading: referralRewardLoading } = useGetReferralRewardQuery();
	const activeReferralReward = referralReward ? referralReward.find(item => item.inprogress) : undefined;
	const isLoading = friendListLoading || referralRewardLoading;

	return (
		<FriendsContainer>
			<Helmet>
				<title>PinEye | Friends</title>
			</Helmet>
			<Header />
			{!isLoading && friendList && activeReferralReward ? (
				<>
					<IncomeStats
						friendsBalance={friendList.referralBalance?.yourExtraBalanceIn24H}
						friendsCount={friendList.friendsCount}
						data={activeReferralReward}
					/>
					<FriendsList data={friendList} />
				</>
			) : (
				<Loader />
			)}
		</FriendsContainer>
	);
};

export default Friends;
