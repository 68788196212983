import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GETPractice, IGetPractice } from 'api/v1/prana-game';

const initialState: IGetPractice = {
	practiceList: [
		{
			id: 0,
			profit: 0,
			cost: 0,
			date: '',
			title: '',
			icon: '',
			modalIcon: 'string',
			modalTitle: '',
			modalText: '',
			practiceTime: 0,
			dayOfWeek: 0,
			claimedStatus: '',
			isCurrent: false,
		},
	],
};
export const Practice = createSlice({
	name: 'practice',
	initialState,
	reducers: {
		changePractice: (state, action: PayloadAction<IGetPractice>) => {
			state = action.payload;
			return state;
		},
	},
});

export const GetPranaPracticeThunk = createAsyncThunk('get_prana_practice', async (_, { dispatch }) => {
	const data = await GETPractice();
	dispatch(changePractice(data));
});
export const { changePractice } = Practice.actions;
export default Practice.reducer;
