import { canClaimReward, ICanClaimRewardResponse } from 'api/v1/secret-code';
import { createCustomApi } from 'store/base.api';
import { AppDispatch } from 'store/types';

const apis = {
	'can-claim': canClaimReward,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data };
};

const DailySecretCodeSliceApi = createCustomApi({
	reducerPath: 'daily-secret-code-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getCanClaim: builder.query<ICanClaimRewardResponse, void>({
			query: () => ({ path: 'can-claim' }),
		}),
	}),
});

export const updateCanClaimStatus = (status: boolean) => (dispatch: AppDispatch) => {
	dispatch(
		DailySecretCodeSliceApi.util.updateQueryData('getCanClaim', undefined, draft => {
			draft.canClaim = status;
		}),
	);
};
export const { useGetCanClaimQuery } = DailySecretCodeSliceApi;

export default DailySecretCodeSliceApi;
