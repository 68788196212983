import { useMatomo } from '@datapunt/matomo-tracker-react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import Boost from 'pages/boost';
import CardGame from 'pages/card-game';
import Earn from 'pages/earn';
import Exchange from 'pages/exchange';
import Friends from 'pages/friends';
import Home from 'pages/home';
import Leagues from 'pages/leagues';
// import Lottery from 'pages/lottery';
import Miner from 'pages/miner';
import Practice from 'pages/practice';
import PranaShop from 'pages/prana-shop';
import RewardHistory from 'pages/reward-history';
import Shop from 'pages/shop';
import Wallet from 'pages/wallet';
import PracticeSecond from 'pages/practice-second';
// import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
// import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from 'shared/components/layout/layout';
import { envResolver } from 'shared/configs/env-resolver';

import { CardHistory } from 'pages/card-history';
import { SecretCode } from 'pages/secret-code';
import Campaign from 'pages/campaign/campaign';
import Academy from 'pages/academy';
import Pyramid from 'pages/pyramid';
const manifestUrl = envResolver.TON_MANIFEST;

const App = () => {
	const { enableLinkTracking } = useMatomo();
	// const { userId } = Telegram().getTelegramData();
	// const dispatch = useDispatch();
	// const balance = useAppSelector(state => state.profile.balance);

	enableLinkTracking();
	return (
		<TonConnectUIProvider
			actionsConfiguration={{
				twaReturnUrl: 'https://t.me/pineye_bot',
			}}
			manifestUrl={manifestUrl}
		>
			<BrowserRouter>
				<Toaster
					position='top-center'
					containerStyle={{
						zIndex: 9999999999,
					}}
					containerClassName='toaster_container'
				/>
				<Layout>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/friends' element={<Friends />} />
						<Route path='/earn' element={<Earn />} />
						<Route path='/boost' element={<Boost />} />
						<Route path='/leagues' element={<Leagues />} />
						<Route path='/reward-history' element={<RewardHistory />} />
						{/* <Route path='/lottery' element={<Lottery />} /> */}
						<Route path='/card-game' element={<CardGame />} />
						<Route path='/card-game/history' element={<CardHistory />} />
						<Route path='/wallet' element={<Wallet />} />
						<Route path='/exchange' element={<Exchange />} />
						<Route path='/games/miner' element={<Miner />} />
						<Route path='/prana/shop' element={<PranaShop />} />
						<Route path='/games/prana/practice/:id' element={<Practice />} />
						<Route path='/games/prana/practice-2/:id' element={<PracticeSecond />} />
						<Route path='/games/miner/shop' element={<Shop />} />
						<Route path='/secret-code' element={<SecretCode />} />
						<Route path='/campaign' element={<Campaign />} />
						<Route path='/academy' element={<Academy />} />
						<Route path='/pyramid' element={<Pyramid />} />
					</Routes>
				</Layout>
			</BrowserRouter>
		</TonConnectUIProvider>
	);
};

export default App;
