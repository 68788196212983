import { FC } from 'react';
import Modal from 'shared/components/modal';
import { InfoModalContainer } from '../css';
import useCloseModal from 'shared/hooks/use-close-modal';
import Button from 'shared/components/button';
import { useNavigate } from 'react-router-dom';

interface IInfoMoalProps {
	status: boolean;
	setStatus: (status: boolean) => void;
	type: string;
	setType: () => void;
}

const InfoModal: FC<IInfoMoalProps> = ({ status, setStatus, type, setType }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const navigate = useNavigate();
	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			<InfoModalContainer>
				{type === 'Prana' ? (
					<img src={'/img/game/buda-modal.webp'} className='calender' />
				) : (
					<img src='/img/prana/profit.webp' className='calender_hi' />
				)}
				<div className='content'>
					<h3>{type}</h3>
					<p>
						{type === 'Prana'
							? 'Prana is the life force energy that flows through all living beings. Pranas vitality lies in its ability to nourish the body and mind, promoting health and balance.'
							: 'Boost your Prana, boost your profits! Every time you improve your Prana, you earn passive coins, helping you grow your wealth effortlessly.'}{' '}
					</p>

					<Button
						variant='primary'
						onClick={() => {
							navigate('/prana/shop');

							onClose();
							setType();
						}}
					>
						{type === 'Prana' ? 'Continue' : 'Make More Profit'}
					</Button>
				</div>
			</InfoModalContainer>
		</Modal>
	);
};

export default InfoModal;
