import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Special, Tab, Academy, Partner } from './components';
import { EarnContainer } from './css';
import { useDispatch } from 'react-redux';
import { changeTasks } from 'store/reducers/profile';
import { useGetDailyRewardsQuery } from 'store/query';
import { useGetSocialQuery } from 'store/query/social';
import Loader from 'shared/components/loader';

const Earn = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState<'SPECIAL' | 'PARTNERS' | 'ACADEMY'>('SPECIAL');

	const { data: dailyData } = useGetDailyRewardsQuery();
	const { data } = useGetSocialQuery();
	const academyData = data ? data.filter(item => item.category === 'Academy') : [];
	const remainSocial = data
		? data.filter(item => !item.isClaimed && item.category === 'Special' && item.subCategory !== 'Partners').length
		: 0;
	const remainAcademy = data ? data.filter(item => !item.isClaimed && item.category === 'Academy').length : 0;
	const remainPartners = data
		? data.filter(item => !item.isClaimed && item.category === 'Special' && item.subCategory === 'Partners').length
		: 0;

	useEffect(() => {
		dispatch(changeTasks({ pendingTasksCount: remainAcademy + remainPartners + remainSocial }));
	}, [remainAcademy, remainPartners, remainSocial]);

	return (
		<EarnContainer>
			<Helmet>
				<title>PinEye | earn</title>
			</Helmet>
			<div className='top_light'></div>
			{/* <Header /> */}
			<Tab
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				remainPartners={remainPartners}
				remaimainSocial={remainSocial}
				remainAcademy={remainAcademy}
			/>
			{(!data || !dailyData) && <Loader />}
			{activeTab === 'SPECIAL' && data && dailyData && <Special socialData={data} dailyData={dailyData} />}
			{activeTab === 'ACADEMY' && academyData && <Academy setAcademyData={() => {}} academyData={academyData} />}
			{activeTab === 'PARTNERS' && dailyData && data && (
				<Partner setDailyData={() => {}} setSocialData={() => {}} socialData={data} dailyData={dailyData} />
			)}
		</EarnContainer>
	);
};

export default Earn;
