import { GETPranaMarketplace, GETWeeklyPractice, IGetPranaMarketplace, IGetWeeklyPractice } from 'api/v1/prana-game';
import { createCustomApi } from 'store/base.api';

const apis = {
	'market-place': GETPranaMarketplace,
	'weekly-practice': GETWeeklyPractice,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const PranaSliceApi = createCustomApi({
	reducerPath: 'prana-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getMarketPlace: builder.query<IGetPranaMarketplace, void>({
			query: () => ({ path: 'market-place' }),
		}),
		getWeeklyPractice: builder.query<IGetWeeklyPractice, void>({
			query: () => ({ path: 'weekly-practice' }),
		}),
	}),
});

export const { useGetMarketPlaceQuery, useGetWeeklyPracticeQuery } = PranaSliceApi;

export default PranaSliceApi;
