import { createSlice } from '@reduxjs/toolkit';
import { IGetCampaignResponse } from 'api/v1/campaign';

const initialState: IGetCampaignResponse = {
	id: 0,
	title: '',
	iconUrl: '',
	reward: 0,
	fromTime: 0,
	isClaimedReward: false,
	isTasksCompleted: false,
	tasks: [],
};

export const Campaign = createSlice({
	name: 'campaign',
	initialState: initialState,
	reducers: {
		changeCampaign: (state, action: { payload: IGetCampaignResponse }) => {
			state = action.payload;
			return state;
		},
	},
});

export const { changeCampaign } = Campaign.actions;
export default Campaign.reducer;
