import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CampaignContainer = styled.div(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		padding: 20px 32px;
		display: flex;
		flex-direction: column;
		gap: 6px;
		justify-content: space-around;
		z-index: 2;
		padding-bottom: 150px;
		background: url(/img/campaign/bg.webp);
		background-position: top left;
		background-size: cover;
		background-repeat: no-repeat;
		.header_img {
			width: 128px;
			position: relative;
			left: 0;
			right: 0;
			margin: auto;
			top: 0px;
		}
		h1 {
			font-size: 21px;
			font-weight: 700;
			line-height: 26px;
			text-align: center;
			z-index: 2;
			position: relative;
			padding-bottom: 20px;
		}
		.reward {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			font-weight: 600;
			line-height: 22.28px;
			padding: 10px 0;
			img {
				width: 21px;
				height: 21px;
				margin: 0 5px 3px;
			}
		}
		.img_item {
			width: 56px;
			height: 56px;
		}

		.timeline_container {
			display: grid;
			grid-template-columns: 56px 1fr;
			grid-template-rows: auto 1fr;
			position: relative;
			align-items: start;
			.number {
				width: 51px;
				height: 51px;
				border-radius: 100%;
				border: 1px solid #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				grid-row: 1;
			}
		}
		.line {
			grid-column: 1;
			grid-row: 2;
			background: repeating-linear-gradient(180deg, white 15px, white 15px, transparent 22px, transparent 8px);
			width: 1px;
			height: 100%;
			margin: 0 auto;
		}
		.item {
			grid-column: 2;
			grid-row: 1 / span 2;
			width: 100%;
			display: flex;
			align-items: center;
			font-size: 14px;
			font-weight: 400;
			display: grid;
			grid-template-columns: 56px auto 30px;
			border-radius: 13px;
			background: #005385;
			padding: 11px 11px 11px 15px;
			gap: 8px;
			.icon {
				width: 29px;
				height: 29px;
			}
			.icon_arrow {
				width: 6.5px;
				height: 13px;
			}
			.icon_loader {
				width: 29px;
				height: 29px;
				animation: rotate 2s linear infinite;
				@keyframes rotate {
					from {
						transform: rotate(0deg);
					}
					to {
						transform: rotate(360deg);
					}
				}
			}
			p {
				font-size: 14px;
				font-weight: 600;
				line-height: 17.33px;
			}
		}
		.btn {
			position: fixed;
			bottom: 14vh;
			left: 0;
			right: 0;
			margin: auto;
			width: 82%;
			z-index: 5;
			margin-top: 10px;
		}
		@media (max-height: 688px) {
			.btn {
				bottom: 16vh;
				z-index: 5;
			}
		}
	`,
);
