import { FC } from 'react';
import { PineyeContainer } from '../css';
import { ArrowRightOutline } from 'shared/components/icons';
import { ILevelResponse } from 'api/v2/levels';

interface PineyeProps {
	activeLevel: number;
	onChangeActiveLeague: (type: 'NEXT' | 'PREV') => void;
	data: ILevelResponse[];
}

const Pineye: FC<PineyeProps> = ({ activeLevel, onChangeActiveLeague, data }) => {
	return (
		<PineyeContainer>
			<img src={`/img/pineye/level-1.webp`} alt='' className={`character ${activeLevel === 1 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/level-2.webp`} alt='' className={`character ${activeLevel === 2 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/level-3.webp`} alt='' className={`character ${activeLevel === 3 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/level-4.webp`} alt='' className={`character ${activeLevel === 4 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/level-5.webp`} alt='' className={`character ${activeLevel === 5 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/level-6.webp`} alt='' className={`character ${activeLevel === 6 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/level-7.webp`} alt='' className={`character ${activeLevel === 7 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/level-8.webp`} alt='' className={`character ${activeLevel === 8 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/level-9.webp`} alt='' className={`character ${activeLevel === 9 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/level-10.webp`} alt='' className={`character ${activeLevel === 10 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/level-11.webp`} alt='' className={`character ${activeLevel === 11 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/level-12.webp`} alt='' className={`character ${activeLevel === 12 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/level-13.webp`} alt='' className={`character ${activeLevel === 13 ? 'active' : ''}`} draggable={false} />
			<div className={`character_light style_${activeLevel}`}></div>
			<div className='arow_container'>
				<div className='circle'>
					<ArrowRightOutline
						className={`arrow_left ${activeLevel === 1 ? 'disable' : ''}`}
						onClick={() => {
							onChangeActiveLeague('PREV');
						}}
					/>
				</div>
				<div className='circle'>
					<ArrowRightOutline
						className={`arrow_right ${activeLevel === 10 ? 'disable' : ''}`}
						onClick={() => {
							onChangeActiveLeague('NEXT');
						}}
					/>
				</div>
			</div>
			<div className='title'>{data[activeLevel - 1].title} League</div>
		</PineyeContainer>
	);
};

export default Pineye;
