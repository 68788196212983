import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CoinsContainer = styled.section(
	({ theme }) => css`
		/* padding-top: 10px; */
		display: flex;
		align-items: flex-start;
		justify-content: center;
		gap: 5px;
		user-select: none;
		position: relative;
		z-index: 2;
		background: transparent;

		div {
			color: ${theme.colors.text.primary};
			font-weight: 600;
			font-size: 23px;
			line-height: 45px;
		}
	`,
);
