import { configureStore } from '@reduxjs/toolkit';
import Profile from './reducers/profile';
import Levels from './reducers/levels';
import Miner from './reducers/miner';
import Campaign from './reducers/camoaign';
import Practice from './reducers/practice';

import {
	PranaSliceApi,
	ReferralRewardSliceApi,
	FriendSliceApi,
	DailyRewardSliceApi,
	GameSliceApi,
	WalletSliceApi,
	// ReferralBalanceSliceApi,
	DailySecretCodeSliceApi,
	BoosterSliceApi,
	RewardHistorySliceApi,
} from './query';
import SocialSliceApi from './query/social';

export default configureStore({
	reducer: {
		profile: Profile,
		levels: Levels,
		miner: Miner,
		campaign: Campaign,
		practice: Practice,
		[PranaSliceApi.reducerPath]: PranaSliceApi.reducer,
		[ReferralRewardSliceApi.reducerPath]: ReferralRewardSliceApi.reducer,
		[FriendSliceApi.reducerPath]: FriendSliceApi.reducer,
		[DailyRewardSliceApi.reducerPath]: DailyRewardSliceApi.reducer,
		[SocialSliceApi.reducerPath]: SocialSliceApi.reducer,
		[GameSliceApi.reducerPath]: GameSliceApi.reducer,
		[WalletSliceApi.reducerPath]: WalletSliceApi.reducer,
		[DailySecretCodeSliceApi.reducerPath]: DailySecretCodeSliceApi.reducer,
		[BoosterSliceApi.reducerPath]: BoosterSliceApi.reducer,
		[RewardHistorySliceApi.reducerPath]: RewardHistorySliceApi.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat([
			PranaSliceApi.middleware,
			FriendSliceApi.middleware,
			ReferralRewardSliceApi.middleware,
			DailyRewardSliceApi.middleware,
			SocialSliceApi.middleware,
			GameSliceApi.middleware,
			WalletSliceApi.middleware,
			DailySecretCodeSliceApi.middleware,
			BoosterSliceApi.middleware,
			RewardHistorySliceApi.middleware,
		]),
});
